<template>
  <div :class="themeClass" :style="context.styles">
    <div v-if="componentControl.errorMessage !== null" class="error">
      {{ componentControl.errorMessage }}
    </div>
    <component v-if="componentControl.visibility" :is="optionComponent" ref="sc" v-bind="computedProps"
               :context="context" />
  </div>
</template>
<script>
import componentExports from '@/components/componentExports';
import { addFontsStyleTag, removeFontsStyleTag, prodHosts } from '@sbx/prec-shared-utils';
import { screenSizesMixin } from '@/utils/mixins/screenSizesMixin';
import { i18n } from '../utils/plugins';
import { withContext } from '@/utils/mixins/withContext';

export default {
  name: 'SCWrapper',
  i18n,
  mixins: [screenSizesMixin, withContext],
  data() {
    return {
      componentExports,
      componentControl: {
        visibility: '',
        errorMessage: null,
      },
    };
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
  },
  watch: {
    computedProps() {
      this.optionsImportValidator();
    },
    context() {
      i18n.locale = this.setLocale();
    },
  },
  computed: {
    computedProps() {
      const opts = typeof this.options === 'string' ? this.validateStringProps() : { ...this.options };
      return {
        ...opts,
        screenSize: this.screenSize,
        getErrorMessage: message => {
          if (!this.componentControl.errorMessage) {
            this.validatorErrorHandler(message);
          }
        },
      };
    },
    optionComponent() {
      const component = this.name in this.componentExports;
      if (component) {
        const mod = this.componentExports[this.name].route;
        return mod;
      }
      return false;
    },
    isProductionServer() {
      return prodHosts.includes(window.location.host);
    },
    themeClass() {
      return `${ this.context.brand }-theme`;
    },
  },
  created() {
    addFontsStyleTag();
  },
  beforeDestroy() {
    removeFontsStyleTag();
  },
  mounted() {
    if (this.computedProps) {
      this.optionsImportValidator();
    }
    i18n.locale = this.setLocale();
  },
  methods: {
    setLocale() {
      const brand = this.context.searchURLHeaders.brand;
      const country = this.context.searchURLHeaders.country;
      const language = this.context.searchURLHeaders.language;
      const locale = `${ brand }_${ country }_${ language }`;

      return locale;
    },
    validatorErrorHandler(message) {
      const hiddenWarning = `the component will be hidden until required properties are passed.`;
      if (this.isProductionServer) {
        this.componentControl.visibility = false;
      } else {
        const title = 'NON-PRODUCTION WARNING - ';
        const warning = `In a production environment this message will be removed, ${ hiddenWarning }`;
        this.componentControl.errorMessage = title + message + warning;
        this.componentControl.visibility = true;
      }
    },
    optionsImportValidator() {
      this.componentControl.errorMessage = null;
      const errorProps = [];
      const { propTypes } = this.componentExports[this.name];
      for (const [propKey, propKeyInfo] of Object.entries(propTypes)) {
        // First, any undefined props and check if required
        const propTypeCheckUndefined = typeof this.computedProps[propKey] == 'undefined';
        if (propTypeCheckUndefined && propKeyInfo.hasOwnProperty('required')) {
          errorProps.push(propKey);
        }
        // Second, check any defined props and if they do not match the type
        if (!propTypeCheckUndefined && typeof propKeyInfo.type() !== typeof this.computedProps[propKey]) {
          errorProps.push(propKey);
        }

        if (propTypes[propKey].validate) {
          const err = propTypes[propKey].validate(this.computedProps[propKey]);

          if (err && err.length) {
            errorProps.push(err.join(','));
          }
        }
      }
      if (errorProps.length) {
        const joined = errorProps.join(', ');
        this.validatorErrorHandler(`[${ joined }] - invalid prop(s). Please check your input options. `);
      } else {
        this.componentControl.visibility = true;
      }
    },
    validateStringProps() {
      try {
        return JSON.parse(this.options);
      } catch (e) {
        console.error(
          `parseError for ${ this.name }, ${ this.context.contextKey }. Please check options`,
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/themes/_themes.scss';
@import '@/scss/variables.scss';

* {
  box-sizing: border-box;
  font-family: $brand-font-regular;
}

:host {
  all: initial;
}

.error {
  max-width: 800px;
  margin: 10px auto;
  border: red 1px dashed;
  padding: 5px 0px;
}

#{$all-brands} {
  width: 100%;
}
</style>
